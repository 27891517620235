<template>
  <div>
    <v-list-item
      :to="toLink"
      class="mb-0"
    >
      <v-list-item-avatar
        rounded
        size="50"
      >
        <v-img
          :src="item.gallery && item.gallery[0] && item.gallery[0].url_thumb || require('@/assets/images/misc/trophy.png')"
          :width="50"
        ></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title
          class="primary--text"
          v-text="item.title"
        ></v-list-item-title>
        <v-list-item-subtitle v-if="item.return_at">
          <span class="text--primary">
            {{ $t('ReturnTime') }}: <strong>{{ formatDate(item.return_at) }}</strong>
          </span>
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          <span class="text--primary">
            {{ $t('CanBorrowTo') }}: <strong>{{ formatDate(item.can_borrow_to) }}</strong>
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action v-if="item.is_edit && shouldShowEdit">
        <v-btn
          x-small
          icon
          color="primary"
          class="mt-2"
          :to="{name: 'staff-sharebox-edit', params:{id: item.id_item}}"
        >
          <v-icon>
            {{ icons.mdiPencilOutline }}
          </v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-divider />
  </div>
</template>

<script>
import Bus from '@/utils/bus'
import { mdiPencilOutline } from '@mdi/js'

export default {
  name: 'ShareBoxItem',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isStaff: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        mdiPencilOutline,
      },
    }
  },
  computed: {
    toLink() {
      return this.isStaff ? `/staff/sharebox/${this.item.id_item}` : `/sharebox/${this.item.id_item}`
    },
    shouldShowEdit() {
      return this.$route.name.includes('staff')
    },
  },
  methods: {
    showEdit(id) {
      Bus.$emit('showEdit', id)
    },
  },
}
</script>
